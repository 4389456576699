import React, { Component } from "react";
import { motion } from "framer-motion";

import Container from "../container";

export class Experience extends Component {
  render() {
    const { resume } = this.props;

    const items = [
      {
        name: "User Interface Design",
        description:
          "I pride myself in my ability to design all visual and interactive elements of a product’s interface.",
        skillgroup: "Web Design and Mobile Design",
      },
      {
        name: "User Experience Design",
        description:
          "I combine user research and standard usability principles to create the framework for how a user will interact with a digital design.",
        skillgroup: "Web Design and Mobile Design",
      },
      {
        name: "UX Writing",
        description:
          "I string words that perfectly communicates a products and organization’s envisioned messages to motivate digital users to intuitively accomplish a goal.",
      },
    ];

    const experienceVariants = {
      hidden: {
        y: 300,
        opacity: 0,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: { delay: 0.7, duration: 1 },
      },
    };

    /* const containerVariants = {
      hidden: {
        opacity: 0,
        y: 200,
      },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.5,
        },
      },
    }; */

    return (
      <motion.section
        className="experience"
        variants={experienceVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Container>
          <h2>Experience</h2>
          <div className="experience__past">
            <h3 className="experience__role">Product Designer</h3>
            <div className="experience__info">
              <p className="experience__company">
                UBA<span>2021</span>
              </p>
              <p>
                At UBA, I worked with a team building an intra-organizational
                complex system; design for Human Resource Management System,
                Performance amd Entreprise Management, etc.
              </p>
            </div>
          </div>
          <motion.div
            /*   variants={containerVariants}
            initial="hidden"
            animate="visible" */
            className="experience__description"
          >
            <div className="experience__description__header">
              <p>What I do</p>
              <hr />
            </div>
            <div>
              {items.map((item, index) => {
                return (
                  <motion.div
                    className="experience__item"
                    /*                   initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0 , opacity: 1}}
                    transition={{ delay: index * 0.5 , duration: 0.5 }}
                    viewport={{ once: true }} */
                  >
                    <div className="experience__item__image">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8049 7.52724C15.8048 7.52716 15.8047 7.52716 15.8047 7.52707L8.47135 0.194926C8.20654 -0.0608112 7.78451 -0.0534881 7.52873 0.21128C7.27922 0.469621 7.27922 0.879141 7.52873 1.1374L13.724 7.33179H0.666667C0.298503 7.33179 0 7.63025 0 7.99835C0 8.36646 0.298503 8.66491 0.666667 8.66491H13.724L7.52865 14.8592C7.26383 15.115 7.25651 15.5369 7.51229 15.8017C7.76807 16.0665 8.19019 16.0739 8.455 15.8181C8.46053 15.8128 8.46598 15.8073 8.47135 15.8017L15.8047 8.46955C16.065 8.20942 16.065 7.78753 15.8049 7.52724Z"
                          fill="white"
                        />
                      </svg>
                    </div>

                    <div className="experience__item__content">
                      <h4>{item.name}</h4>
                      <p>{item.description}</p>
                      <small>{item.skillgroup}</small>
                    </div>
                  </motion.div>
                );
              })}

              {resume && (
                <motion.div
                  className="experience__item"
                  /*                   initial={{ y: 200, opacity: 0 }}
                animate={{ y: 0 , opacity: 1}}
                transition={{ delay: index * 0.5 , duration: 0.5 }}
                viewport={{ once: true }} */
                >
                  <div className="experience__item__image">
                    <svg
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_709_6273)">
                        <path
                          d="M4.97728 31.4136H24.0336C24.3928 31.4136 24.6867 31.1197 24.6867 30.7605V28.4226H27.0246C27.3838 28.4226 27.6777 28.1287 27.6777 27.7695V8.6936C27.6777 8.51728 27.6059 8.35401 27.4883 8.22993L20.1675 0.909114C20.0499 0.791563 19.8801 0.719727 19.7103 0.719727H7.9683C7.60912 0.719727 7.31524 1.0136 7.31524 1.37279V3.71075H4.97728C4.6181 3.71075 4.32422 4.00462 4.32422 4.36381V30.7605C4.32422 31.1197 4.6181 31.4136 4.97728 31.4136ZM20.3634 2.94667L25.4508 8.03401H20.3634V2.94667ZM8.62136 2.02585H19.0573V8.6936C19.0573 9.05279 19.3512 9.34667 19.7103 9.34667H26.3781V27.123H8.62136V2.02585ZM5.63034 5.01687H7.31524V27.7695C7.31524 28.1287 7.60912 28.4226 7.9683 28.4226H23.3805V30.1075H5.63034V5.01687Z"
                          fill="white"
                        />
                        <path
                          d="M19.2275 13.8921C19.5866 13.8921 19.8805 13.5982 19.8805 13.2391C19.8805 11.2211 18.6593 9.49049 16.9156 8.73947C17.2944 8.29539 17.5295 7.73375 17.5295 7.10681C17.5295 5.70273 16.3866 4.56641 14.9891 4.56641C13.5915 4.56641 12.4487 5.70926 12.4487 7.10681C12.4487 7.73375 12.6838 8.29539 13.0626 8.73947C11.3189 9.49049 10.0977 11.2211 10.0977 13.2391C10.0977 13.5982 10.3915 13.8921 10.7507 13.8921H19.2275ZM14.9826 5.87253C15.6617 5.87253 16.2168 6.42763 16.2168 7.10681C16.2168 7.786 15.6617 8.3411 14.9826 8.3411C14.3034 8.3411 13.7483 7.786 13.7483 7.10681C13.7483 6.42763 14.3034 5.87253 14.9826 5.87253ZM14.9826 9.64722C16.7393 9.64722 18.2087 10.9142 18.5156 12.586H11.4495C11.7564 10.9207 13.2258 9.64722 14.9826 9.64722Z"
                          fill="white"
                        />
                        <path
                          d="M24.2527 16.1514H10.7344V17.4575H24.2527V16.1514Z"
                          fill="white"
                        />
                        <path
                          d="M24.2527 19.7109H10.7344V21.0171H24.2527V19.7109Z"
                          fill="white"
                        />
                        <path
                          d="M24.2527 23.2695H10.7344V24.5757H24.2527V23.2695Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_709_6273">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0 0.0664062)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div className="experience__item__content">
                    <h4>Resume</h4>
                    <p>Oreoluwanimi Adeyemi.pdf</p>
                  </div>
                </motion.div>
              )}
            </div>
          </motion.div>
        </Container>
      </motion.section>
    );
  }
}

export default Experience;
